import Chip from '@material-ui/core/Chip';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { useLocation } from '@reach/router';
import { Link, navigate, withAssetPrefix } from 'gatsby';
import React from 'react';

import Layout from '@/components/layout';
import SEO from '@/components/seo';

const noImage = '/assets/images/noImage.jpg';

interface NewsListProps {
  classes: Record<string, string>;
  pageContext: {
    newsList: {
      edges: Array<{
        node: {
          id: string;
          title: string;
          date: string;
          excerpt: string;
          featured_media?: { source_url: string };
          tags: Array<{ name: string; slug: string }>;
        };
      }>;
    };
  };
}

const NewsList: React.FC<NewsListProps> = ({ classes, pageContext }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tagFilter = params.get('tag');

  const _formatDate = (wordpressDate: string | number | Date) => {
    const dateObj = new Date(wordpressDate);
    return (
      dateObj.getFullYear() +
      '.' +
      (dateObj.getMonth() + 1) +
      '.' +
      dateObj.getDate()
    );
  };

  const __handleOnClick = (id: string) => () => {
    navigate(`/news/${id}`);
  };

  const filterNewsByTag = (tag: string) => {
    const { newsList } = pageContext;
    return newsList.edges.filter(({ node }) =>
      node.tags?.some(t => t.name === tag)
    );
  };

  const filteredNewsList = tagFilter
    ? filterNewsByTag(tagFilter)
    : pageContext.newsList.edges;

  return (
    <Layout>
      <SEO title="NEWS一覧" />
      <div className={classes.root}>
        <div className={classes.breadCrumb}>
          <Link to={'/'}>TOP</Link>
          <span className={classes.breadCrumbSplit}> {'>'} </span>
          <Link to={'/news'}>NEWS一覧</Link>
          {tagFilter && (
            <>
              <span>&nbsp;&nbsp;#{tagFilter}</span>
            </>
          )}
        </div>
        <div>
          {filteredNewsList.map(({ node }) => {
            return (
              <div
                className={classes.itemBox}
                onClick={__handleOnClick(node.id)}
              >
                <img
                  className={classes.img}
                  src={
                    node.featured_media && node.featured_media.source_url
                      ? withAssetPrefix(node.featured_media.source_url)
                      : withAssetPrefix(noImage)
                  }
                  alt={node.title}
                />
                <div className={classes.texts}>
                  <div className={classes.date}>{_formatDate(node.date)}</div>
                  <div className={classes.title}>{node.title}</div>
                  <div className={classes.tag}>
                    {node.tags.map(tag => (
                      <Chip
                        size="small"
                        key={tag.slug}
                        label={`#${tag.name}`}
                        color="secondary"
                        variant="outlined"
                      />
                    ))}
                  </div>
                  <div
                    className={classes.excerpt}
                    dangerouslySetInnerHTML={{ __html: node.excerpt }}
                  ></div>
                  <div className={classes.allReadLink}>
                    <Link to={`/news/${node.id}`}>全て読む</Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '40px 80px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 10px',
      },
    },
    breadCrumb: {
      fontFamily:
        '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
      fontSize: 18,
      marginBottom: 40,
      marginLeft: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        marginBottom: 30,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
        marginBottom: 10,
      },
    },
    breadCrumbSplit: {
      fontFamily: 'Advent Pro',
      fontWeight: 'bold',
      marginLeft: 10,
      marginRight: 10,
      color: '#999',
    },
    itemBox: {
      backgroundColor: 'white',
      borderRadius: '7px',
      margin: '20px 20px',
      padding: '20px 20px',
      display: 'flex',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        padding: '10px 10px',
        flexWrap: 'wrap',
      },
    },
    img: {
      width: '200px',
      height: '200px',
      objectFit: 'cover',
      marginBottom: 0,
      [theme.breakpoints.down('sm')]: {
        width: '150px',
        height: '150px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '200px',
      },
    },
    texts: {
      marginLeft: '20px',
      marginRight: '40px',
      textDecoration: 'none',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
    date: {
      fontFamily: 'Advent Pro',
      fontStyle: 'normal',
      fontStretch: 'normal',
      fontSize: 22,
      letterSpacing: 1.1,
      marginBottom: theme.spacing(1.5),
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0),
      },
    },
    title: {
      fontFamily:
        '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
      fontSize: 20,
      background: 'linear-gradient(transparent 50%, #ffff6b 0%)',
      display: 'inline',
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 13,
      },
    },
    excerpt: {
      fontFamily:
        '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
      fontSize: 15,
      marginTop: '10px',
      display: 'show',
      '& > p': {
        marginBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
        marginTop: '10px',
        display: 'show',
      },
      [theme.breakpoints.down('xs')]: {
        //display: 'none',
      },
    },
    allReadLink: {
      textAlign: 'right',
      position: 'relative',
      fontFamily:
        '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
      fontSize: 18,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    tag: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(1),
      '& > *': {
        marginRight: '10px', // Chip同士の間隔を10pxに設定
      },
    },
  });

export default withStyles(styles)(NewsList);
